










































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";
import { AnalyticsPoster, AnalyticsVisitorWithHits } from "@/types/interfaces";


export default Vue.extend ({
    components: {
        AnalyticsDateControl
    },
    computed: {

        loading(): boolean {
            return analyticsStore.loading;
        },
    
        posterId(): string {
            return this.$route.params.posterId ? this.$route.params.posterId : "";
        },
    
        itemData(): AnalyticsPoster {
            return analyticsStore.singlePoster;
        },
    
        users(): Array<AnalyticsVisitorWithHits> {
            return this.itemData.users;
        },
    
        posterName(): string {
            return this.itemData.posterName;
        },
    
        tableHeaders(): Array<string> {
            return [
                "Attendee Id",
                "First Name",
                "Last Name",
                "Company Name",
                "Email",
                "Visits"
            ];
        },
    
        tableFooter(): Array<string> {
            return [
                "Total Visitors",
                `${this.totalVisitors}`,
                "",
                "",
                "Total Visits",
                `${this.totalVisits}`
            ];
        },
    
        totalVisits(): number {
            return this.users.reduce((acc, curr) => {
                return (acc += curr.hits);
            }, 0);
        },
    
        csvUrl(): string {
            return this.getCsvUrl();
        },
    
        totalVisitors(): number {
            return this.users.length;
        }
    },
    methods: {
        getCsvUrl() {
            const valuesOnly = this.users.map((item) => [
                item.uid,
                item.firstName,
                item.lastName,
                item.companyName,
                item.email,
                item.hits ? `${item.hits}` : "0"
            ]);
            const dataArray = [this.tableHeaders, ...valuesOnly, this.tableFooter];
    
            return transformDataToCsvUrl(dataArray);
        },
    
        getData() {
            analyticsStore.getSinglePosterVisits(this.posterId);
        }

    },

    mounted() {
        this.getData();
    }

})
